.PhotoPicker__photoPickerButton___2XdVn {
  width: 100%;
}

.PhotoPicker__photoPlaceholder___2JXO4 {
  border: 2px dotted var(--grey);
  padding: 64px 0 64px;
}

.PhotoPicker__photoPlaceholderIcon___3Et71 {
  text-align: center;
  opacity: 0.2;
}