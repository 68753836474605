.Input__input___3e_bf {
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 14px;
  color: var(--input-color);
  background-color: var(--input-background-color);
  background-image: none;
  border: 1px solid var(--lightGrey);
  border-radius: 3px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.Input__input___3e_bf:disabled {
  color: #545454;
  background-color: var(--silver);
}

.Input__inputLabel___3VF0S {
  color: var(--input-color);
  font-size: 14px;
  margin-bottom: 8px;
}

.Input__label___23sO8 {
  color: var(--input-color);
}

.Input__radio___2hllK {
  margin-right: 18px;
  vertical-align: bottom;
}

@media only screen and (max-width: 599px) {
  .Input__input___3e_bf {
    font-size: 16px;
  }
}