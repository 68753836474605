.Form__formContainer___1GA3x {
  text-align: center;
  margin-top: 20px;
  margin: 5% auto 50px;
}

.Form__formSection___1PPvW {
  position: relative;
  margin-bottom: 20px;
  background-color: var(--form-color);
  padding: 35px 40px;
  text-align: left;
  display: inline-block;
  min-width: 460px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);
  box-sizing: border-box;
}

.Form__formField___38Ikl {
  margin-bottom: 22px;
}

.Form__formRow___2mwRs {
  margin-bottom: 12px;
}

@media only screen and (max-width: 599px) {
  .Form__formContainer___1GA3x {
    margin: 0;
  }

  .Form__formSection___1PPvW {
    width: 100%;
    box-sizing: border-box;
    padding: 35px 25px;
    box-shadow: none;
    border-radius: 0;
    min-width: auto;
  }
}