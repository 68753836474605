/*
 * Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */
:root {

  /* Colors */
  --amazonOrange: #FF9900;
  --lightAmazonOrange: #FFAC31;
  --darkAmazonOrange: #E88B01;
  --squidInk: #232F3E;
  --lightSquidInk: #31465F;
  --deepSquidInk: #152939;
  --grey: #828282;
  --lightGrey: #C4C4C4;
  --silver: #E1E4EA;
  --darkBlue: #31465F;
  --red: #DD3F5B;
  --white: #FFFFFF;
  --light-blue: #00a1c9;
  

  /* Theme */
  --button-color: var(--white);
  --button-background-color: var(--amazonOrange);
  --button-click: var(--darkAmazonOrange);
  --link-color: var(--amazonOrange);
  --form-color: var(--white);
  --input-color: var(--deepSquidInk);
  --input-background-color: var(--white);

  --font-family: "Amazon Ember","Helvetica Neue Light","Helvetica Neue","Helvetica" ,"Arial","sans-serif";
  --body-background: #F8F4F4;

  /** Angular Theme **/
  --component-width-desktop: 460px;
  --component-width-mobile: 100%;

  --color-primary: #FF9900;
  --color-primary-accent: #232F3E;
  --color-primary-highlight: #FFC46D;
  
  --color-background:#232F3E;

  --color-secondary: #152939;
  --color-secondary-accent: #31465F;

  --color-danger: #DD3F5B;
  --color-error: #D0021B;

  --color-accent-brown: #828282;
  --color-accent-blue: #E1E4EA;

  --gradient-blaze: linear-gradient(270deg, #FFC300 0%, #FF9000 100%);

  --color-blue: #007EB9;
  --color-purple: #527FFF;
  --color-gray: #828282;
  --color-white: #FFFFFF;

  --input-border: 1px solid #C4C4C4;
  --input-padding: 0.5em 0.5em 0.3em 1em;

  --box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);
  --button-height: 42px;

  --interactions-conversation-height: 250px;

  /* Ionic Theme */
  
  /** primary **/
  --ion-color-primary: #FF9900;
  --ion-color-primary-rgb: 255,153,0;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #232F3E;
  --ion-color-primary-tint: #FFC46D;

  /** secondary **/
  --ion-color-secondary: #32db64;
  --ion-color-secondary-rgb: 50,219,100;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #2cc158;
  --ion-color-secondary-tint: #47df74;

  /** tertiary **/
  --ion-color-tertiary: #f4a942;
  --ion-color-tertiary-rgb: 244,169,66;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #d7953a;
  --ion-color-tertiary-tint: #f5b255;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16,220,96;
  --ion-color-success-contrast: #fff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f53d3d;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d83636;
  --ion-color-danger-tint: #f65050;

  /** light **/
  --ion-color-light: #f4f4f4;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d7d7;
  --ion-color-light-tint: #f5f5f5;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  
  /** dark **/
  --ion-color-dark: #222;
  --ion-color-dark-rgb: 34,34,34;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e1e1e;
  --ion-color-dark-tint: #383838;
}
